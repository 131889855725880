import * as React from "react"
import PropTypes from "prop-types";

import CreditItem from "../components/credit-item";

const Credits = ( { data } ) => {
    const title = data && data.sectionTitle ? data.sectionTitle : ''
    const credits = data && data.credits ? data.credits : []
	return (
		<section className="credits content-block">
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="cell ">
						<h2>{title}</h2>
					</div>
				</div>

				<div className="grid-x grid-margin-x credits__list-box">
					<div className="cell">
                        {credits.map((credit, i) => {
                            let creditTitle = credit && credit.title ? credit.title : ''
                            let creditContent = credit && credit.content ? credit.content : {}
                            let creditPartners = credit && credit.partners ? credit.partners : [];
                            return (<CreditItem key={credit.id} title={creditTitle} content={creditContent} partners={creditPartners}/>)
                        })}
					</div>
				</div>
			</div>
		</section>
	)
}
Credits.propTypes = {
	data: PropTypes.object,
}
Credits.defaultProps = {
	data: {}
}
export default Credits