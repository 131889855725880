import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const MemberItem = ({ member }) => {
  const memberPhoto =
    member?.photo?.gatsbyImageData?.images?.sources[0]?.srcSet?.childImageSharp
      ?.gatsbyImageData
  const memberName = member && member.name ? member.name : 'Lorem ipsum.'
  const memberPos =
    member && member.position ? member.position : 'Lorem ipsum dolor.'
  const memberLink = member && member.slug ? '/leadership/' + member.slug : '#'
  return (
    <article className="member-item">
      {memberPhoto && (
        <Link
          to={memberLink}
          className="member-item__photo-box rel-wrap"
          aria-hidden={true}
        >
          <GatsbyImage
            image={getImage(memberPhoto)}
            alt={`${memberName}`}
            className="member-item__photo stretched-img"
          />
        </Link>
      )}

      <div className="member-item__info">
        <h4
          className="member-item__name"
          sx={{
            width: '100%',
            fontSize: [24, null, null, 18],
          }}
        >
          <Link
            to={memberLink}
            className="member-item__link"
            aria-label={`Go to ${memberName} details page`}
            style={{
              width: '100%',
            }}
          >
            {memberName}
          </Link>
        </h4>
        <p
          className="member-item__pos"
          style={{
            width: '90%',
          }}
          dangerouslySetInnerHTML={{ __html: memberPos }}
        ></p>
      </div>
    </article>
  )
}

MemberItem.propTypes = {
  member: PropTypes.object,
}

export default MemberItem
