import * as React from "react"
import PropTypes from "prop-types";

import FlexibleList from "../helpers/flexible-list.js";

const Flexible = ({data}) => {
    return (
        <div className="flexible-list">
            {data.map((layout, i) => {
                if(layout && layout.sys){
                    const FlexibleLayout = (layout.sys && layout.sys.contentType ) && FlexibleList[layout.sys.contentType.sys.id];
                    return (<FlexibleLayout key={layout.id} data={layout}></FlexibleLayout>)
                }
                return false
            })}
        </div>
    )
}
Flexible.propTypes = {
    data: PropTypes.array,
}
Flexible.defaultProps = {
    data: []
}
export default Flexible