import * as React from 'react'
import PropTypes from 'prop-types'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options.js'
import LinkItem from '../components/link-item'
import CompareItem from '../components/compare-item'

const Solutions = ({ data }) => {
  const content = data && data.content ? data.content : false
  const link = data && data.link ? data.link : false
  const list = data && data.list ? data.list : false
  const tableTitle = data && data.tableTitle ? data.tableTitle : ''
  const country1 = data && data.country1 ? data.country1 : ''
  const country2 = data && data.country2 ? data.country2 : ''
  return (
    <section className="content-block solutions">
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-bottom">
          <div className="cell large-6 medium-9">
            <div className="section-content">
              {content && renderRichText(content, RichTextOptions)}
            </div>
          </div>
          <div className="cell large-6 large-text-right solutions__btn-box show-for-large">
            {link && <LinkItem link={link} key={link.id} cssClass={'button'} />}
          </div>
        </div>

        <div className="grid-x grid-margin-x solutions__table-row">
          <div className="cell solutions__table-box">
            <div className="compare-table">
              <div className="compare-table__head small-text">
                <div className="compare-table__row">
                  <div className="compare-table__cell compare-table__cell--title">
                    <p>{tableTitle}</p>
                  </div>
                  <div className="compare-table__cell text-center">
                    <p>{country1}</p>
                  </div>
                  <div className="compare-table__cell text-center">
                    <p>{country2}</p>
                  </div>
                </div>
              </div>
              <div className="compare-table__body">
                {list &&
                  list.map((compare, i) => {
                    return <CompareItem data={compare} key={compare.id} />
                  })}
              </div>
            </div>
          </div>

          <div className="cell solutions__btn-box hide-for-large">
            {link && <LinkItem link={link} key={link.id} cssClass={'button'} />}
          </div>
        </div>
      </div>
    </section>
  )
}
Solutions.propTypes = {
  data: PropTypes.object,
}
Solutions.defaultProps = {
  data: {},
}
export default Solutions
