import * as React from 'react'
import PropTypes from 'prop-types'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options.js'
import { stripEmptyTags } from '../helpers/helpers'

import CtaBox from '../components/cta-box'
import Stats from '../components/stats'
import Feature from '../components/feature'
import BallsDecor from '../components/balls-decor'

const CPTList = {
  cptStats: Stats,
  cptFeature: Feature,
  cptCtaBox: CtaBox,
}

const Features = ({ data }) => {
  const content = data && data.content ? stripEmptyTags(data.content) : false
  const isDisplay = data && data.sys.display != null ? false : true
  const cta = data && data.cta ? data.cta : false
  const type = data && data.type ? data.type : 'Light'
  const blockClass = `content-block content-block--high features features--${type.toLowerCase()} ${
    cta ? 'features--has-cta' : ''
  }`
  const countByRow = data && data.countByRow ? parseInt(data.countByRow) : 4
  const list = data && data.list ? data.list : []

  return (
    <section
      className={
        isDisplay !== false
          ? blockClass
          : 'content-block content-block--high features'
      }
      sx={
        !content.raw
          ? {
              background: '#f1f2f2!important',
              height: ['10px!important', null, '50px!important'],
              padding: '0px!important',
            }
          : {
              padding:
                isDisplay == false
                  ? ['15px!important', null, null, '30px!important']
                  : '',
            }
      }
    >
      {type === 'Dark' && <BallsDecor position="top" />}
      {isDisplay !== false && (
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div
              className={`cell large-${
                cta ? 'auto' : '9'
              } large-order-1 small-order-2`}
            >
              {content && (
                <div className="section-content">
                  {content && renderRichText(content, RichTextOptions)}
                </div>
              )}
            </div>
            {cta && (
              <div className="cell large-shrink large-order-2 medium-6 medium-offset-6 large-offset-0 small-order-1 features__top-cta">
                <CtaBox data={cta} />
              </div>
            )}
          </div>
          <div
            className={`grid-x grid-padding-x features__list-row features__list-row--${countByRow}`}
          >
            {list &&
              list.map((item, y) => {
                const CPTLayout = CPTList[item.sys.contentType.sys.id]
                console.log(item)
                return (
                  item.display !== false && (
                    <div
                      className={`cell medium-6 large-${
                        countByRow === 3 ? '4' : '3'
                      } features__list-item features__list-item--${
                        item.sys.contentType.sys.id
                      }`}
                      key={item.id}
                    >
                      <CPTLayout data={item}></CPTLayout>
                    </div>
                  )
                )
              })}
          </div>
        </div>
      )}
    </section>
  )
}
Features.propTypes = {
  data: PropTypes.object,
}
Features.defaultProps = {
  data: {},
}
export default Features
