import * as React from 'react'
import PropTypes from 'prop-types'

import LinkItem from '../components/link-item.js'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options.js'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const TextImg = ({ data }) => {
  const image =
    data?.image?.gatsbyImageData?.images?.sources[0]?.srcSet?.childImageSharp
      ?.gatsbyImageData

  // data?.image?.gatsbyImageData && getImage(data.image.gatsbyImageData)
  const content = data && data.content ? data.content : false
  const link = data && data.link ? data.link : false
  const direction =
    data && data.contentDirection ? data.contentDirection : 'Image First'
  const gridClass =
    direction === 'Image first'
      ? 'grid-x grid-margin-x align-middle large-flex-dir-row-reverse'
      : 'grid-x grid-margin-x align-middle'
  return (
    <section
      className={`content-block text-img ${
        direction === 'Image first' ? 'text-img--reverse' : ''
      }`}
    >
      <div className="grid-container">
        <div className={gridClass}>
          <div className="cell large-6 medium-11 text-img__content section-content">
            {content && renderRichText(content, RichTextOptions)}
            {link && <LinkItem cssClass={'button'} link={link}></LinkItem>}
          </div>

          <div className="cell large-6 text-center">
            {image && (
              <GatsbyImage
                image={image}
                alt={data.image.title}
                className="text-img__media"
              />
            )}
            {/*
            {image && (
              <img
                src={
                  data?.image?.gatsbyImageData?.images?.sources[0]?.srcSet
                    .publicURL
                }
              />
            )} */}
          </div>
        </div>
      </div>
    </section>
  )
}
TextImg.propTypes = {
  data: PropTypes.object,
}
TextImg.defaultProps = {
  data: {},
}
export default TextImg
