import * as React from 'react'
import PropTypes from 'prop-types'
import LinkItem from './link-item'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const OpportunityItem = ({ oppInfo }) => {
  const icon = oppInfo?.icon
  const title = oppInfo?.title || false
  const content = oppInfo?.content || false
  const list = oppInfo?.sideContent || false
  const link = oppInfo?.link || false

  return (
    <div className="opp-item small-text">
      <div className="grid-x grid-margin-x opp-item__row">
        <div className="cell large-9 medium-7">
          <div className="opp-item__card">
            {icon && (
              <div className="opp-item__icon-box">
                {/* {icon?.gatsbyImageData ? (
                  <GatsbyImage
                    image={getImage(icon.gatsbyImageData)}
                    alt={title}
                    className="opp-item__icon"
                  />
                ) : ( */}
                <div
                  className="opp-item__icon"
                  dangerouslySetInnerHTML={{
                    __html: icon.svg.originalContent,
                  }}
                />
                {/* <img
                  src={icon.svg.originalContent}
                  alt={title}
                  className="opp-item__icon"
                /> */}
                {/* )} */}
              </div>
            )}

            {title && <h3 className="opp-item__title">{title}</h3>}

            {content && (
              <div className="opp-item__text">
                {renderRichText(content, RichTextOptions)}
              </div>
            )}
          </div>
        </div>
        <div className="cell large-3 medium-5">
          <div className="opp-item__side">
            {list && (
              <div className="opp-item__list">
                {renderRichText(list, RichTextOptions)}
              </div>
            )}
          </div>
        </div>
      </div>
      {link && (
        <LinkItem
          link={link}
          cssClass="button button_apply-today"
          cssActiveClass="button opp-item__link"
        />
      )}
    </div>
  )
}

OpportunityItem.propTypes = {
  oppInfo: PropTypes.object,
}

export default OpportunityItem
