import * as React from "react"
import PropTypes from "prop-types";
import Feature from "../components/feature";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {RichTextOptions} from "../helpers/rich-text-options";
import BallsDecor from "../components/balls-decor";

const Benefits = ( { data } ) => {
	const title = data?.titleSection || false;
	const features = data?.list || false;
    const smallDescription = data?.smallDescription || false
    return (
		<section className="content-block benefits inherit-color">
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					{title &&
						<div className="cell large-4 medium-6 benefits__title-box">
							<h2>{title}</h2>
						</div>
					}
					<div className="cell medium-auto benefits__list-box">
						<div className="grid-x grid-margin-x benefits__list">
							{features && features.map( ( item, i ) => {
								return (
									<div className="cell large-4 medium-6 benefits__item" key={item.id}>
										<Feature data={item}/>
									</div>
								)
							} )}
							{smallDescription && <div className="cell large-4 medium-6 benefits__item">
								<div className="benefits__small">{renderRichText( smallDescription, RichTextOptions )}</div>
							</div>}
						</div>
					</div>
				</div>
			</div>
			<BallsDecor position="bottom"/>
		</section>
	)
}
Benefits.propTypes = {
    data: PropTypes.object,
}
Benefits.defaultProps = {
    data: {}
}
export default Benefits