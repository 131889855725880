import * as React from "react"
import PropTypes from "prop-types";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {RichTextOptions} from "../helpers/rich-text-options"

const Stats = ({data}) => {
    const title = data && data.title ? data.title : ''
    const titleColor = data && data.titleColor ? data.titleColor : 'blue'
    const content = data && data.shortDescription ? data.shortDescription : false
    const titleClass = `stat-item__value stat-item__value--${titleColor}`
    return (
        <div className="stat-item small-text">
            {title && <h3 className={titleClass}>{title}</h3>}
            <div className="stat-item__descr">
                {content && renderRichText(content, RichTextOptions)}
            </div>
        </div>
    )
}
Stats.propTypes = {
    data: PropTypes.object,
}
Stats.defaultProps = {
    data: {}
}
export default Stats