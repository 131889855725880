import * as React from 'react'
import PropTypes from 'prop-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { CreditRichTextOptions } from '../helpers/credit-rich-text-options.js'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const creditItem = ({ title, content, partners }) => {
  return (
    <div className="credit-item">
      <div className="grid-x grid-margin-x">
        <div className="cell large-6">
          <div className="credit-item__content">
            {title && <h4 className="credit-item__title">{title}</h4>}
            {content && renderRichText(content, CreditRichTextOptions)}
          </div>
        </div>
        <div
          className="cell large-6 credit-item__partners-box"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {partners &&
            partners.map((partner, i) => {
              return (
                partner?.gatsbyImageData && (
                  <a
                    href={partner.description}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={partner.id}
                    style={{ maxWidth: '137px' }}
                  >
                    <GatsbyImage
                      alt={partner.title}
                      image={getImage(
                        partner?.gatsbyImageData?.images?.sources[0]?.srcSet
                          ?.childImageSharp?.gatsbyImageData
                      )}
                      className="credit-item__logo"
                    />
                  </a>
                )
              )
            })}
        </div>
      </div>
    </div>
  )
}

creditItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
  partners: PropTypes.array,
}
export default creditItem
