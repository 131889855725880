import * as React from 'react'
import PropTypes from 'prop-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options'

const Feature = ({ data }) => {
  const title = data && data.title ? data.title : ''
  const icon = data && data.icon ? data.icon.svg.originalContent : false
  const description =
    data && data.shortDescription ? data.shortDescription : false
  return (
    <div className="feature-item">
      {icon && (
        <div className="feature-item__icon-box">
          <div
            className="feature-item__icon"
            dangerouslySetInnerHTML={{
              __html: icon,
            }}
          />
          {/* <img
            alt={icon.title ? icon.title : ''}
            className="feature-item__icon"
            height="60"
            src={icon}
            width="60"
          /> */}
        </div>
      )}
      {title && (
        <h6
          className="feature-item__title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h6>
      )}

      {description && (
        <div className="feature-item__descr small-text">
          {renderRichText(description, RichTextOptions)}
        </div>
      )}
    </div>
  )
}
Feature.propTypes = {
  data: PropTypes.object,
}
Feature.defaultProps = {
  data: {},
}
export default Feature
