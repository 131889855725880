import * as React from "react"
import PropTypes from "prop-types";

const CompareItem = ({data}) => {
    const title  = data && data.title ? data.title : ''
    const us     = data && data.us ? data.us : 'off'
    const canada = data && data.canada ? data.canada : 'off'
    return (
        <div className="compare-table__row">
            <div className="compare-table__cell compare-table__cell--title"><h4 className="compare-table__title">{title}</h4></div>
            <div className="compare-table__cell text-center">{(us === 'on') && <span className="compare-table__check"></span>}</div>
            <div className="compare-table__cell text-center">{(canada === 'on') && <span className="compare-table__check"></span>}</div>
        </div>
    )
}
CompareItem.propTypes = {
    data: PropTypes.object,
}
CompareItem.defaultProps = {
    data: {}
}
export default CompareItem