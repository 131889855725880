import Banner from '../flexible/banner'
import Credits from '../flexible/credits'
import Features from '../flexible/features'
import TextImg from '../flexible/text-img'
import Members from '../flexible/members'
import Solutions from '../flexible/solutions'
import TextList from '../flexible/text-list'
import Opportunities from '../flexible/opportunities'
import Benefits from '../flexible/benefits'

const flexibleSection = {
  banner: Banner,
  credits: Credits,
  features: Features,
  blockTextImage: TextImg,
  blockMembers: Members,
  blockSolutions: Solutions,
  blockTextListing: TextList,
  blockOpportunities: Opportunities,
  blockBenefits: Benefits,
}

export default flexibleSection
