import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import MemberItem from "./member-item";

const BallsDecor = ( { position } ) => {
	return (
		<div className={`balls-decor balls-decor--${position}`} data-scroll="">
			<StaticImage src="../images/big-ball.png" placeholder="none" alt="" className="balls-decor__item balls-decor__item--big"/>
			<StaticImage src="../images/small-ball.png" placeholder="none" alt="" className="balls-decor__item balls-decor__item--small"/>
		</div>
	)
}

MemberItem.propTypes = {
	position: PropTypes.string,
}

export default BallsDecor