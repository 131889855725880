import * as React from "react"
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types'
function formatSubtitle(node, children){
    return <h4 className="credit-item__title">{children}</h4>
}

export const CreditRichTextOptions = {
    renderMark: {
        [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
            const { uri } = node.data
            return (
                <a href={uri} className="underline">
                    {children}
                </a>
            )
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
            return <a href={node.data.target.sys.id}>{children}</a>;
        },
        [BLOCKS.HEADING_1]: formatSubtitle,
        [BLOCKS.HEADING_2]: formatSubtitle,
        [BLOCKS.HEADING_3]: formatSubtitle,
        [BLOCKS.HEADING_4]: formatSubtitle,
        [BLOCKS.HEADING_5]: formatSubtitle,
        [BLOCKS.HEADING_6]: formatSubtitle,
        [BLOCKS.UL_LIST]: (node, children) => {
            return <ul className="credit-item__list small-text">{children}</ul>
        },
        [BLOCKS.LIST_ITEM]: (node, children) => {
            return <li className="credit-item__list-item">{children}</li>
        }
    },
    renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
}