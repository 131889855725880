import * as React from "react"
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types'

export const CtaBoxRichTextOptions = {
    renderMark: {
        [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
            const { uri } = node.data
            return (
                <a href={uri} className="underline">
                    {children}
                </a>
            )
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
            return <a href={node.data.target.sys.id}>{children}</a>;
        },
        [BLOCKS.PARAGRAPH ]: (node, children) => {
            return <p className="cta-block__descr small-text">{children}</p>
        }
    },
    renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
}