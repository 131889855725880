import * as React from 'react'
import PropTypes from 'prop-types'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { CtaBoxRichTextOptions } from '../helpers/cta-box-rich-text-options'
import LinkItem from './link-item'

const CtaBox = ({ data }) => {
  const title = data && data.title ? data.title : ''
  const content = data && data.content ? data.content : ''
  const decor = data && data.decor ? data.decor : 'off'
  const blockClass = `cta-block ${decor === 'on' ? 'cta-block--decor' : ''}`
  const link = data && data.link ? data.link : false
  return (
    <div className={blockClass}>
      <h3 className="cta-block__title">{title}</h3>
      {content && renderRichText(content, CtaBoxRichTextOptions)}
      {link && <LinkItem link={link} key={link.id} cssClass={'button small'} />}
    </div>
  )
}
CtaBox.propTypes = {
  data: PropTypes.object,
}
CtaBox.defaultProps = {
  data: {},
}
export default CtaBox
