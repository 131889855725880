import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'

import Flexible from '../components/flexible.js'

// Component queries
import '../graphql/fragments/graphBlockBanner.js'
import '../graphql/fragments/graphBlockHistory.js'
import '../graphql/fragments/graphBlockCredits'
import '../graphql/fragments/graphBlockFeatures'
import '../graphql/fragments/graphBlockTextImage'
import '../graphql/fragments/graphBlockMembers'
import '../graphql/fragments/graphBlockSolutions'
import '../graphql/fragments/graphBlockTextList'
import '../graphql/fragments/graphBlockBenefits'
import '../graphql/fragments/graphBlockOpportunities'

const getHeaderClass = function (arr) {
  if (
    arr &&
    arr[0] &&
    arr[0].sys &&
    arr[0].sys.contentType.sys.id === 'banner' &&
    arr[0].backgroundColorScheme
  ) {
    return arr[0].backgroundColorScheme
  }
  return 'light'
}

const PageTemplate = ({ data }) => {
  const page = data.contentfulPageJson
  const flexibleArr = page.flexibleContent

  return (
    <Layout header={getHeaderClass(flexibleArr)}>
      <Flexible data={flexibleArr}></Flexible>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => {
  const page = data.contentfulPageJson
  return <Seo title={page.pageTitle} />
}

export default PageTemplate

export const query = graphql`
  query GetPage($slug: String!) {
    contentfulPageJson(pageSlug: { eq: $slug }) {
      pageSlug
      pageTitle
      flexibleContent {
        backgroundColorScheme
        backgroundType
        bannerType
        bannerTitle {
          raw
        }
        type
        titleSection
        title
        sys {
          display
          contentType {
            sys {
              id
              linkType
              type
            }
          }
        }
        id
        link {
          externalLink
          id
          linkType
          title
          internalPage {
            id
            pageSlug
            pageTitle
          }
        }
        textSize
        tableTitle
        sectionTitle
        smallDescription {
          raw
        }
        cta {
          title
          content {
            raw
          }
          decor
          sys {
            contentType {
              sys {
                id
                linkType
                type
              }
            }
          }
        }
        list {
          us
          display
          titleColor
          title
          sys {
            contentType {
              sys {
                id
                linkType
                type
              }
            }
          }
          slug
          sideContent {
            raw
          }
          shortDescription {
            raw
          }
          position
          photo {
            title
            gatsbyImageData {
              height
              images {
                sources {
                  sizes
                  type
                  srcSet {
                    childImageSharp {
                      gatsbyImageData(formats: PNG, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
          name
          mainContent {
            raw
          }
          link {
            externalLink
            id
            linkType
            title
          }
          id
          icon {
            id
            title
            svg {
              originalContent
            }
          }
          decor
          content {
            raw
          }
          canada
          bio {
            raw
          }
          additionalContent {
            raw
          }
        }
        image {
          title
          gatsbyImageData {
            height
            images {
              sources {
                sizes
                type
                srcSet {
                  childImageSharp {
                    gatsbyImageData(formats: PNG, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        content {
          raw
        }
        contentDirection
        countByRow
        country2
        country1
        credits {
          id
          content {
            raw
          }
          title
          partners {
            description
            gatsbyImageData {
              height
              images {
                sources {
                  sizes
                  type
                  srcSet {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }

            id
            title
          }
        }
      }
    }
  }
`
