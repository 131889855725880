import * as React from 'react'
import PropTypes from 'prop-types'

import MemberItem from '../components/member-item'

const Members = ({ data }) => {
  const members = data && data.list ? data.list : []

  return (
    <section className="members content-block">
      <div
        className="grid-container"
        sx={{
          px: '2em!important',
        }}
      >
        <div
          className="grid-x"
          sx={{
            display: ['flex', null, null, 'none'],
            justifyContent: 'space-around',
          }}
        >
          {members &&
            members.map((member, i) => {
              return (
                <div
                  className="cell large-3 medium-6 member-item-box"
                  key={member.id}
                >
                  <MemberItem member={member} />
                </div>
              )
            })}
        </div>
        <div
          className="grid-x"
          sx={{
            display: ['none', null, null, 'flex'],
            justifyContent: ['space-around', 'center'],
          }}
        >
          {members &&
            members.map((member, i) => {
              return (
                i <= 3 && (
                  <div
                    className="cell large-3 medium-6 member-item-box"
                    key={member.id}
                    sx={{
                      px: '2rem!important',
                    }}
                  >
                    <MemberItem member={member} />
                  </div>
                )
              )
            })}
        </div>
        <div
          className="grid-x"
          sx={{
            display: ['none', null, null, 'flex'],
            justifyContent: ['center'],
          }}
        >
          {members &&
            members.map((member, i) => {
              return (
                i >= 4 && (
                  <div
                    className="cell large-3 medium-6 member-item-box"
                    key={member.id}
                    sx={{
                      px: '2rem!important',
                    }}
                  >
                    <MemberItem member={member} />
                  </div>
                )
              )
            })}
        </div>
      </div>
    </section>
  )
}
Members.propTypes = {
  data: PropTypes.object,
}
Members.defaultProps = {
  data: {},
}
export default Members
