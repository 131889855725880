import * as React from "react"
import PropTypes from "prop-types";
import OpportunityItem from "../components/opp-item";

const Opportunities = ( { data } ) => {
	const title = data && data.title ? data.title : 'Opportunities';
	const opportunities = data && data.list ? data.list : false;
	return (
		<section className="opportunities content-block content-block--high">
			<div className="grid-container">
				{title &&
					<div className="grid-x grid-margin-x opportunities__title-box">
						<div className="cell ">
							<h2 className="opportunities__title">{title}</h2>
						</div>
					</div>
				}
				<div className="grid-x grid-margin-x opportunities__cards">
					<div className="cell ">
						{opportunities && opportunities.map( ( oppInfo, i ) => {
							return (
								<OpportunityItem oppInfo={oppInfo} key={oppInfo.id}/>
							)
						} )}
					</div>
				</div>
			</div>
		</section>
	)
}
Opportunities.propTypes = {
	data: PropTypes.object,
}
Opportunities.defaultProps = {
	data: {}
}
export default Opportunities